import {useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { type } from '../config';
import { useDispatch } from 'react-redux';
import { Pathname } from '../Redux/Redux/Action/Action';

const AdminGuard = ({ children }) => {
  const dispatch=useDispatch();
  const location = useLocation();
    const user=JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  
 useEffect(() => {
  if (user?.userType !== type.admin) {
    navigate('/dashboard')
 }
 dispatch(Pathname(location.pathname))
}, [user]);



  return <>
  {children}</>;
};
export default AdminGuard;
