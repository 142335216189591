import React, { useEffect } from 'react'
import Header from './header'
import Footer from './footer'
import Sidebar from './Sidebar'
import { callAPI } from '../utils/apiUtils'
import { apiUrls } from '../utils/apiUrls'
import { ErrorMessage } from '../helpers/common';
import { useDispatch } from 'react-redux';
import { CommonInfo } from '../Redux/Redux/Action/Action';
import { useSelector } from 'react-redux';
import { contentState } from '../Redux/Redux/Reducer/contentState';

export default function SidebarLayout(props) {
  const dispatch = useDispatch()
  const commonState = useSelector((store) => store.CommonInfo.commonInfo)


  const getContent = async () => {
    try {
      const response = await callAPI(apiUrls.contentLists, {}, 'GET')
      if (response.data.status) {
        let data = response?.data?.data
        delete data["execTime"]
        dispatch(CommonInfo(data))
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (JSON.stringify(commonState) == JSON.stringify(contentState)) {
      getContent()
    }
  }, [])

  return (
    <>
      <Header contents={commonState?.cmsList} sticky_id={"sticky"} />
      <section>
        <div className="container container_1310">
          <div className="dashboard">
            <Sidebar />
            {props.children}
          </div>
        </div>
      </section>
      <Footer contents={commonState?.cmsList} />
    </>
  )
}
