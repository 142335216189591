import React, { useEffect, useState } from 'react'
import i18n from '../i18n/i18n';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import AuthLogin from './Headerpage/AuthLogin';


export default function Header({ contents, sticky_id ="" }) {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

  const chooseLanguage = (value) => {
    localStorage.setItem("language", value);
    i18n.changeLanguage(value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    setSelectedLanguage(value);
  }

  let user = JSON.parse(localStorage.getItem('userData'))

  // useEffect(() => {
  //   // Define the googleTranslateElementInit function
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement(
  //       { pageLanguage: 'en' },
  //       'google_translate_element'
  //     );
  //   };

  //   // Load Google Translate API script
  //   const script = document.createElement('script');
  //   script.src =
  //     '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   // Cleanup
  //   return () => {
  //     document.body.removeChild(script);
  //     delete window.googleTranslateElementInit;
  //   };
  // }, []);



  return (

    <>
      <header id={sticky_id}>
        {/* main bar */}
        <nav className="navbar navbar-expand-lg bg-white custom-navbar">

          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src="/image/logo.png" alt="" />
            </Link>
            <div className='d-flex align-items-center'>
              <div className='d-block d-md-none mbphoneflex'>
                <AuthLogin t={t} isheader={true} />
              </div>
              <button
                className="navbar-toggler ms-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/">
                    {t("home")}
                  </Link>
                </li>
                {
                  contents?.length > 0 && contents.map((content, i) => (

                    content?.isHeader == 1 && <li key={i} className="nav-item">
                      <Link to={`/cms-page/${content?._id}`} className="nav-link">{content?.title}</Link>
                    </li>

                  ))
                }

                {/* <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to={"/company"}>
                    {t("company")}
                  </Link>
                </li>
                
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to={"/service-provider"}>
                    {t("service-provider")}
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link className="nav-link" to="/faqs">
                    {t("faq")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/contact-us">
                    {t("contact")}
                  </Link>
                </li>
              
              </ul>
              <div className="d-flex align-items-center justify-content-end right-nav-sec">
                {/* <div id="google_translate_element"></div> */}
                {/* <div className="dropdown">
                  <Link
                    to=""
                    className="dropdown-toggle dropdown-toggle-custom"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="/image/language-icon.svg" className="img-fluid" alt="" />{" "}
                    {CapitalizeLetter(selectedLanguage)}

                  </Link>

                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-custom">
                    <li>

                      <Link className="dropdown-item" to="#" onClick={() => { chooseLanguage("en") }}>
                        <span className='me-2'>

                        </span>
                        English
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#" onClick={() => { chooseLanguage("da") }}>
                        <span className='me-2'>

                        </span>
                        {t("danish")}
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <AuthLogin t={t} />
              </div>
            </div>
          </div>
        </nav>

        {/* main bar end */}
      </header>
    </>
  )
}
