import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import Store from './Redux/Redux/Store';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SocialLogins } from './config';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <GoogleOAuthProvider clientId={SocialLogins.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
      <Provider store={Store}>
        <ToastContainer />
          <App />
      </Provider>
    </GoogleOAuthProvider>

  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
