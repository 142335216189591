import {useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../Redux/Redux/Action/Action';
import { useDispatch } from 'react-redux';

const Guest = ({ children }) => {
  const dispatch=useDispatch();
  const location = useLocation();
    const token = localStorage.getItem('accessToken')
  const navigate = useNavigate();
  
 useEffect(() => {
  if (token) {
    navigate('/dashboard')
 }
 dispatch(Pathname(location.pathname))
}, [token,navigate]);



  return <>
  {children}</>;
};
export default Guest;