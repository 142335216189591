import { useEffect } from "react";
import { type } from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pathname } from "../Redux/Redux/Action/Action";

const ClientGuard = ({ children }) => {
  const dispatch=useDispatch();
  const location = useLocation();

    const user=JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  
 useEffect(() => {
  if (user?.userType !== type.client) {
    navigate('/dashboard')
 }
 dispatch(Pathname(location.pathname))
}, [user]);



  return <>
  {children}</>;
};
export default ClientGuard;

