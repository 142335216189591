import {useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../Redux/Redux/Action/Action';

const Authenticated = ({ children }) => {
  const dispatch=useDispatch();
  const location = useLocation();
 
    const token = localStorage.getItem('accessToken')
  const navigate = useNavigate();
  
 useEffect(() => {
  if (!token) {
    navigate('/get-started')
 }
 dispatch(Pathname(location.pathname))
}, [token]);



  return <>
  {children}</>;
};
export default Authenticated;