import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SideBarLink from './SideBarLink';
import { AwadedJobSVG, CompleteJobs, Dashboardsvg, DeleteSvg, EditSvg, FAQSvg, InvoiceHistory, Job, LogoutSvg, ManageCMS, ManageClientSVG, ManageFaq, ManageFeedback, ManageJobSVG, ManageLanguges, ManageLogoBoards, ManageMeeting, ManageMessage, ManageServices, ManageShift, ManageSkills, ManageSupSVG, ManageTestimonials, Myteam, Notificationsvg, RelevantJobs, SavedJob, TransactionHistory, UpdatePasswordSvg, UpdateProfileSvg } from '../SvgFile/Index';
import { useSelector } from 'react-redux';
import { defaultConfig, type } from '../config';
import Logout from './Headerpage/Logout';
import {CircularProgress} from '@mui/material';
import { useTranslation } from 'react-i18next';


export default function Sidebar() {
    const [links, setLinks] = React.useState([]);
    const [color, setColor] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [toggle, setToggle] = useState(true)
    const [action, setAction] = useState("logout")

    const handleClickOpen = (type="logout") => {
        console.log(type)
        setAction(type)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { t } = useTranslation();

    const client_links = [
        {
            link: "/dashboard",
            title: t("Dashboard"),
            icone: <Dashboardsvg />,
        },

        {
            link: "/profile",
            title: t("Update_Profile"),
            icone: <UpdateProfileSvg />,
        },

        {
            link: "/update-password",
            title: t("Update_Password"),
            icone: <UpdatePasswordSvg/>,
        },
       

        {
            link: "/jobs",
            title: t("Jobs"),
            icone: <ManageJobSVG />,
        },

        {
            link: "/awarded-jobs",
            title: t("Awarded_Jobs"),
            icone: <AwadedJobSVG />,
        },
        {
            link: "/completed-jobs",
            title: t("Complete_Jobs"),
            icone: <CompleteJobs/>,
        },
        
        {
            link: "/meetings",
            title: t("Meetings"),
            icone: <ManageMeeting/>,
        },
        {
            link: "/messages",
            title: t("Messages"),
            icone: <ManageMessage />,
        },

        {
            link: "/notification",
            title: t("Notification"),
            icone: <Notificationsvg />,
        },

        {
            link: "/invoice/list",
            title: t("Invoice History"),
            icone: <InvoiceHistory />,
        },
        
       

    ];

    const service_links = [
        {
            link: "/dashboard",
            title: t("Dashboard"),
            icone: <Dashboardsvg />,
        },
        {
            link: "/profile",
            title: t("Update_Profile"),
            icone: <UpdateProfileSvg />,
        },
        {
            link: "/update-password",
            title: t("Update_Password"),
            icone: <UpdatePasswordSvg/>,
        },
        {
            link: "/worker",
            title: t("My_team"),
            icone: <Myteam />,
        },
    
        {
            link: "/jobs",
            title: t("Jobs"),
            icone: <ManageJobSVG />,
        },
        {
            link: "/saved-jobs",
            title: t("Saved_Jobs"),
            icone: <SavedJob />,
        },
        {
            link: "/applied-jobs",
            title:  t("Applied_Jobs"),
            icone: <RelevantJobs />,
        },
        {
            link: "/awarded-jobs",
            title: t("Awarded_Jobs"),
            icone: <AwadedJobSVG />,
        },
        {
            link: "/completed-jobs",
            title: t("Complete_Jobs"),
            icone: <CompleteJobs/>,
        },
        {
            link: "/relevant-jobs",
            title: t("Relevent_Jobs"),
            icone: <RelevantJobs />,
        },
        {
            link: "/messages",
            title: t("Messages"),
            icone: <ManageMessage />,
        },

        {
            link: "/notification",
            title: t("Notification"),
            icone: <Notificationsvg />,
        },
        {
            link: "/invoice/list",
            title: t("Invoice History"),
            icone: <InvoiceHistory />,
        },

    ];

    const admin_links = [
        {
            link: "/dashboard",
            title: t("Dashboard"),
            icone: <Dashboardsvg />,
        },
        {
            link: "/update-password",
            title: t("Update_Password"),
            icone: <UpdatePasswordSvg/>,
        },

        {
            link: "/client",
            title: t("Manage_Clients"),
            icone: <ManageClientSVG/>,
        },
        {
            link: "/supplier",
            title: t("Manage_Suppliers"),
            icone: <ManageSupSVG />,
        },
        {
            link: "/jobs",
            title: t("Jobs"),
            icone: <ManageJobSVG />,
        },
        {
            link: "/admin/awarded-jobs",
            title: t("Awarded_Jobs"),
            icone: <AwadedJobSVG />,
        },

        {
            link: "/completed-jobs",
            title: t("Complete_Jobs"),
            icone: <CompleteJobs/>,
        },
        {
            link: "/admin/meetings",
            title: t("Manage_Meetings"),
            icone: <ManageMeeting/>,
        },
        {
            link: "/admin/message",
            title: t("Manage_Message"),
            icone: <ManageMessage />,
        },
        {
            link: "/notification",
            title: t("Notification"),
            icone: <Notificationsvg />,
        },
        {
            link: "/invoice/list",
            title: t("Invoice History"),
            icone: <InvoiceHistory/>,
        },
        {
            link: "/admin/feedback",
            title: t("Manage_Feedbacks"),
            icone: <ManageFeedback />,
        },
       
        {
            link: "/add-Service",
            title: t("Manage_Services"),
            icone: <ManageServices/>,
        },
        {
            link: "/add-language",
            title: t("Manage_Languages"),
            icone: <ManageLanguges />,
        },
        {
            link: "/add-logo",
            title: t("Manage_Logo_Boards"),
            icone: <ManageLogoBoards/>,
        },
        {
            link: "/testimonial",
            title: t("Manage_Testimonials"),
            icone: <ManageTestimonials/>,
        },
       
        {
            link: "/cms",
            title: t("Manage_CMSs"),
            icone: <ManageCMS/>,
        },
        {
            link: "/faq",
            title: t("Manage_FAQs"),
            icone: <ManageFaq/>,
        },
        
        {
            link: "/shift",
            title: t("Manage_Shifts"),
            icone: <ManageShift/>,
        },
        {
            link: "/admin/skilllist",
            title: t("Manage_Skills"),
            icone: <ManageSkills/>,
        },
       
      


    ];

    const users = useSelector((state) => state.UserProfile.profile);
    const user = JSON.parse(localStorage.getItem('userData'));
    const lang=localStorage.getItem("language");

    React.useEffect(() => {
        if (user?.userType === type.client) {
            setLinks(client_links);
            setColor(
                "client_dashboard"
            );
        } else if (user?.userType === type.supplier) {
            setLinks(service_links);
            setColor(
                ""
            );

        } else {
            setLinks(admin_links);
            setColor(
                ""
            );

        }
    }, [lang]);


    return (
        <>
            <div className={`dashboard-menu ${color}`}>
                <div className="school-title position-relative">

                <div className='percentdiv'>
                <CircularProgress variant="determinate" value={users?.profilePercentage ? users?.profilePercentage:user?.profilePercentage} />
                </div>

                    <div className="school-logo mb-4">
                       
                            <img src={users.profilePic ? 
                                defaultConfig.imagePath + users?.profilePic 
                                : user?.profilePic ? defaultConfig.imagePath + user?.profilePic 
                                : '/image/tuser.png'} alt=''/>
                     
                    </div>
                    {user?.userType !== type.admin && <div className='countertxt'>{users?.profilePercentage ? `${users?.profilePercentage} %`:`${user?.profilePercentage} %`}</div>
}
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <h3 className="mt-0 notranslate">{users?.firstName ? users?.firstName : user?.firstName}</h3>
                        {user?.userType !== type.admin &&
                            <Link to="/profile">
                                <EditSvg />
                            </Link>
                        }
                        
                    </div>
                    <p className='notranslate'>{users?.email ? users?.email : user?.email}</p>
                   
                </div>
                <button id="user_nav" onClick={() => setToggle(!toggle)} >
                    Menu <img src="/image/nav-bar.png" alt='' />
                </button>
                <div className={toggle ? "user-menu" : "user-menu active"}>
                    {/* <h6 className="dash_heading">dashboard</h6> */}
                    {links.map((item, i) => {
                   
                        return (
                            <SideBarLink
                                key={i}
                                href={item?.link}
                                name={item.title}
                                icon={item.icone}
                                handleclick={(()=>setToggle(true))}
                               
                            />
                        );
                    })}

                    <Link to="#" onClick={()=>{handleClickOpen("logout")}}>
                        <span>
                            <LogoutSvg />
                        </span>
                       {t("logout")}

                    </Link>
                    {user?.userType != type.admin && <Link to="#" onClick={()=>{handleClickOpen("delete")}}>
                        <span>
                            <DeleteSvg />
                        </span>
                        {t("delete")}
                    </Link>}
                </div>
            </div>

            <Logout open={open} handleClose={handleClose} type={action} />

        </>
    )
}


