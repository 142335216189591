export const defaultConfig = {
    //Brain Tech Server
    // baseAPIUrl:"http://192.168.0.3:3000/api/v1/", 
    // imagePath:"http://192.168.0.3:3000/",

    //Live Url https://michelle.etrueconcept.com/
    // baseAPIUrl:"https://michelle.etrueconcept.com/api/v1/", 
    // imagePath:"https://michelle.etrueconcept.com",
    
    //Aws Server http://gomichelle.de
    baseAPIUrl:"https://www.gomichelle.de/api/v1/", 
    imagePath:"https://www.gomichelle.de",
   
    };

export const type = {
    admin:"admin",
    client:"client",
    supplier:"supplier",
    worker:"worker"
}

export const socialLinks = {
    facebook:"https://www.facebook.com/profile.php?id=61556143662093",
    instagram:"https://www.instagram.com/gomichelle.de",
    linkedin:"https://www.linkedin.com/company/gomichelle/about/?viewAsMember=true"
}


export const contactDetails ={
    phone:" +49 (0) 2161 90 29 558",
    email:"match@gomichelle.de",
    instagram:"gomichelle.de"
}

export const DownaloadAppLink = {
    appstore:"",
    googleplay:"https://play.google.com/store/apps/details?id=com.de.gomichelle.bluecollar",
}
export const SocialLogins = {
    REACT_APP_GOOGLE_LOGIN_CLIENT_ID:"69781198871-mbo4dge0uqf0v8iutdklunvdmnkj66tl.apps.googleusercontent.com",
    REACT_APP_FACEBOOK_APP_ID:"2255968508078089"
}

export const moneySign = "€"


 //Brain Tech Server
 //export const termAndCondition = "65bcc06147b0e284c4259d8a";
//  export const Privacy_Policy = "65bcbc7a4377ca75773b7a1d";
//  export const Terms_of_use = "65bccc2547b0e284c425a2ac";

   //Live Url https://michelle.etrueconcept.com/
 //export const termAndCondition = "65c22745d65555e6b9be4b2d";
//  export const Privacy_Policy = "65a8d1d05ab590dae7291e32";
//  export const Terms_of_use = "65c22721d65555e6b9be4b1b";

//Aws Server http://gomichelle.de
 export const termAndCondition = "65c22745d65555e6b9be4b2d";
 export const Privacy_Policy = "65a8d1d05ab590dae7291e32";
 export const Terms_of_use = "65c22721d65555e6b9be4b1b";
































































    