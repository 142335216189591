import './App.css';
import { RenderRout } from './router';
import WithGoogleTranslate from './GoogleTranslate';
// import { useEffect } from 'react';


function App() {
  // const removeNotranslateClass = () => {
  //   const elements = document.querySelectorAll('.notranslate');
  //   elements.forEach(element => {
  //     element.classList.remove('notranslate');
  //   });
  // };
 
  // useEffect(() => {
  //   removeNotranslateClass();
  // }, []);

  return (
    <>
 
   <WithGoogleTranslate/>
      <RenderRout />
    </>
  );
}

export default App;
