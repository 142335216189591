import React, { useEffect} from "react";
import { useSelector } from "react-redux";
     
const WithGoogleTranslate = () => {

  const Allpath=["/client-register","/client-register/"]
  const PathName = useSelector((store) => store.Pathname.pathname)

  useEffect(() => {
    if (!window.googleTranslateElementInit) {
      window.googleTranslateElementInit = function() {
        new window.google.translate.TranslateElement(
          { pageLanguage: 'de' },
          'google_translate_element'
        );
      };

      // Load Google Translate API script
      const script = document.createElement('script');
      script.src =
        'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.head.appendChild(script);

      // Cleanup function
      return () => {
        document.head.removeChild(script);
      };
    } else {
      // If the script is already loaded, directly call the init function
      window.googleTranslateElementInit();
    }
  }, []);

  return (
    <>
    {Allpath.includes(PathName) ?<div id="google_translate_element" className="hide_goole"></div>:<div id="google_translate_element"></div>}
    </>
 
  );
};

export default WithGoogleTranslate;
