import React from 'react'
import { Link } from 'react-router-dom';
import { DownaloadAppLink, socialLinks, type } from '../config'
import { useTranslation } from 'react-i18next';
export default function Footer({ contents }) {
  const user = JSON.parse(localStorage.getItem("userData"))
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer">
        <div className="footer-up-links">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="new_project">
                  <h2>{t("bottomText")}</h2>
                  {user == null && <p>{t("footer_sign_up_text")}</p>}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="new_project d-flex justify-content-md-end gap-3 mb-0">
                  {(user == null || user?.userType == type.supplier) && <Link to={user == null ? "/login/supplier" : "/job-list"} className="primery_btn_outline">
                    {t("footer_search_job")}
                  </Link>}
                  {(user == null || user?.userType == type.client) && <Link to={user == null ? "/login/client" : "/jobs-post"} className="primery_btn">
                    {t("footer_post_job")}
                  </Link>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="footer-outline">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-4 mb-4">
                <div className="luxurycarmarket">
                  <img src="/image/w-logo.png" alt='' />
                  <p>
                    {t("footer_bottom_text")}

                  </p>
                  <div className="social_connect">
                    <Link to={socialLinks?.facebook} target='_blank'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M14.6539 11.25L15.2094 7.63047H11.7364V5.28164C11.7364 4.29141 12.2215 3.32617 13.777 3.32617H15.3559V0.244531C15.3559 0.244531 13.9231 0 12.5531 0C9.69299 0 7.82346 1.73359 7.82346 4.87187V7.63047H4.64417V11.25H7.82346V20H11.7364V11.25H14.6539Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                    <Link to={socialLinks?.linkedin} target='_blank'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5.16719 17.5001H1.53906V5.8165H5.16719V17.5001ZM3.35117 4.22275C2.19102 4.22275 1.25 3.26182 1.25 2.10166C1.25 1.54439 1.47137 1.00995 1.86542 0.615907C2.25947 0.221861 2.79391 0.000488281 3.35117 0.000488281C3.90844 0.000488281 4.44288 0.221861 4.83692 0.615907C5.23097 1.00995 5.45234 1.54439 5.45234 2.10166C5.45234 3.26182 4.51094 4.22275 3.35117 4.22275ZM18.7461 17.5001H15.1258V11.8126C15.1258 10.4571 15.0984 8.71885 13.2395 8.71885C11.3531 8.71885 11.0641 10.1915 11.0641 11.7149V17.5001H7.43984V5.8165H10.9195V7.41025H10.9703C11.4547 6.49229 12.6379 5.52354 14.4031 5.52354C18.075 5.52354 18.75 7.9415 18.75 11.0821V17.5001H18.7461Z"
                          fill="white"
                        />
                      </svg>
                    </Link>

                    <Link to={socialLinks?.instagram} target='_blank'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={25}
                        height={25}
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M12.5049 6.88477C9.39941 6.88477 6.89453 9.38965 6.89453 12.4951C6.89453 15.6006 9.39941 18.1055 12.5049 18.1055C15.6104 18.1055 18.1152 15.6006 18.1152 12.4951C18.1152 9.38965 15.6104 6.88477 12.5049 6.88477ZM12.5049 16.1426C10.498 16.1426 8.85742 14.5068 8.85742 12.4951C8.85742 10.4834 10.4932 8.84766 12.5049 8.84766C14.5166 8.84766 16.1523 10.4834 16.1523 12.4951C16.1523 14.5068 14.5117 16.1426 12.5049 16.1426ZM19.6533 6.65527C19.6533 7.38281 19.0674 7.96387 18.3447 7.96387C17.6172 7.96387 17.0361 7.37793 17.0361 6.65527C17.0361 5.93262 17.6221 5.34668 18.3447 5.34668C19.0674 5.34668 19.6533 5.93262 19.6533 6.65527ZM23.3691 7.9834C23.2861 6.23047 22.8857 4.67773 21.6016 3.39844C20.3223 2.11914 18.7695 1.71875 17.0166 1.63086C15.21 1.52832 9.79492 1.52832 7.98828 1.63086C6.24023 1.71387 4.6875 2.11426 3.40332 3.39355C2.11914 4.67285 1.72363 6.22559 1.63574 7.97852C1.5332 9.78516 1.5332 15.2002 1.63574 17.0068C1.71875 18.7598 2.11914 20.3125 3.40332 21.5918C4.6875 22.8711 6.23535 23.2715 7.98828 23.3594C9.79492 23.4619 15.21 23.4619 17.0166 23.3594C18.7695 23.2764 20.3223 22.876 21.6016 21.5918C22.8809 20.3125 23.2812 18.7598 23.3691 17.0068C23.4717 15.2002 23.4717 9.79004 23.3691 7.9834ZM21.0352 18.9453C20.6543 19.9023 19.917 20.6396 18.9551 21.0254C17.5146 21.5967 14.0967 21.4648 12.5049 21.4648C10.9131 21.4648 7.49023 21.5918 6.05469 21.0254C5.09766 20.6445 4.36035 19.9072 3.97461 18.9453C3.40332 17.5049 3.53516 14.0869 3.53516 12.4951C3.53516 10.9033 3.4082 7.48047 3.97461 6.04492C4.35547 5.08789 5.09277 4.35059 6.05469 3.96484C7.49512 3.39355 10.9131 3.52539 12.5049 3.52539C14.0967 3.52539 17.5195 3.39844 18.9551 3.96484C19.9121 4.3457 20.6494 5.08301 21.0352 6.04492C21.6064 7.48535 21.4746 10.9033 21.4746 12.4951C21.4746 14.0869 21.6064 17.5098 21.0352 18.9453Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12 mb-lg-0 mb-md-4 mb-4">
                <div className="footer_links">
                  <h4>{t("Services")}</h4>
                  <ul>
                    <li>
                      <Link to={user == null ? "/get-started" : "/job-list"}>{t("Browse_Jobs")}</Link>
                    </li>


                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12 mb-lg-0 mb-md-4 mb-4">
                <div className="footer_links">
                  <h4>{t("Company")}</h4>
                  <ul>
                    <ul>
                      <li>
                        <Link to="/about-us">{t("About_us")}</Link>
                      </li>

                      <li>
                        <Link to="/faqs">  {t("faq")}</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">  {t("contact")}</Link>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12 mb-lg-0 mb-md-4 mb-4">
                <div className="footer_links">
                  <h4>{t("Support")}</h4>
                  <ul>
                    {
                      contents?.length > 0 && contents.map((content, i) => (

                        content?.isFooter == 1 && <li key={i}>
                          <Link to={`/cms-page/${content?._id}`}>{content?.title}</Link>
                        </li>

                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12 mb-lg-0 mb-md-4 mb-4">
                <div className="footer_links">


                  <h4>{t("Download Now")}</h4>


                  <Link
                    to={DownaloadAppLink.googleplay}
                    rel="noreferrer"

                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      alt="michelle app download"
                      src="/image/android-app_v1.png"
                      height={44}
                      width={149}
                    />
                  </Link>
                  <Link
                    to={DownaloadAppLink.appstore}
                    rel="noreferrer"
                    target="_blank"
                    className="mt-3"
                  >
                    <img
                      loading="lazy"
                      className='app-t'
                      alt="michelle app download"
                      src="/image/ios-app_v1.png"
                      height={44}
                      width={149}
                    />
                  </Link>


                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="rights">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <p>{t("Copyright")} @{new Date().getFullYear()} Michelle.</p>
              <p>
                {t("Developed by")}:{" "}
                <Link to="https://www.braintechnosys.com/" target="_blank">
                  Brain Technosys{" "}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>


    </>

  )
}
