import { Actiontypes } from "../Action/ActionType";
import { type } from "../../../config";

const intialState = {
   commonInfo: {
    "cmsList":[],
    "languageList": [],
    "serviceList": [],
    "shiftList": [],
    "skills": [],
    "execTime": 1350
  },
  
  notificationCount:"",
  pathname:"",
  
  profile: {},
  serviceproviderdata: {
    userType: type.supplier,
    companyName:"",
    vat:"",
    contactPerson:[],
    companyRegistrationNumber:"",
    companyDialCode:"+49",
    companyPhoneNumber:"",
    companyEmail:"",
    companyAddress:"",
    firstName: "",
    lastName: "",
    dialCode:"",
    phoneNumber: "",
    email: "",
    address: "",
    password: "",
    password_confirmation:"",
    serviceType:[],
    specialSkillsOrExp:[],
    languageProficiency:[],
    userDeclaration:"",
    license:"",
    dailyHours:"",
    weeklyHours:"",
    categoryAvailability:[{
      category:"",
      numberOfResource:"",
      areaRegion:[]
    }],
    toolsAndEquipment: [],
    previousWorkExperience: [{
      workTitle: "",
      workRole: "",
      jobPeriod:""
    }], 
    reference: [{ name: "", number: "" }],
    // introductoryVideo: "",
    resume:"",
    certificate:"",
    // paymentMode:"inCash",
    hourlyRate:"",
    additionalComment:"",
    termAndCondition:"",
    accountNo:"",
    accountName:"",
    branchName:"",
    minPeriod:""
  },

};
const workerDetail = {

  AddWorkerData: {
    // userType: type.worker,
    fullName: "",
    // lastName: "",
    email: "",
    dialCode:"+49",
    phoneNumber: "",
    dob: "",
    nationality: "",
    // password: "",
    // password_confirmation: "",
    serviceType:[],
    languageSpoken: [],
    license:"",
    company: "",
    position: "",
    startDate:"",
    endDate:"",
    previousWorkExperience: [{
      workTitle: "",
      workRole: ""
    }], 
    reference: [{ name: "", number: "" }],
    jobType: "fullTime",
    workingHrs: "",
    shift: "",
    serviceAreaLocationPrefernces: [{ serviceArea: "", locationPreference: "",city:"",state:"",country:"",lat:"",long:"" }],
    insuranceName: "",
    liabilityInsuranceName: "",
    workerCompensation: "",
    passportIdNumber: "",
    highestQualification: "",
    institution: "",
    deggre: "",
    fieldOfStudy: "",
    cv: "",
    certification: "",
    insuranceDocuments: "",
    passportOrId: "",
    proficiency : "fresher",
    comments: "",
    termAndCondition:"0",
    isAddFavorite:"0",
  },


};


export const UserProfile = (state = intialState, { type, payload }) => {
  switch (type) {
    case Actiontypes.PROFILE:
      return { ...state, profile: payload };
    default:
      return state;
  }
};

export const ServiceProviderData = (state = intialState, { type, payload }) => {
  switch (type) {
    case Actiontypes.REGISTER:
      return { ...state, serviceproviderdata: payload };
    default:
      return state;
  }
};
export const AddWorkerData = (state = workerDetail, { type, payload }) => {
  switch (type) {
    case Actiontypes.ADDWORKER:
      return { ...state, AddWorkerData: payload };
    default:
      return state;
  }
};
export const EditWorkerData = (state = workerDetail, { type, payload }) => {
  switch (type) {
    case Actiontypes.EDITWORKER:
      return { ...state, EditWorkerData: payload };
    default:
      return state;
  }
};

export const NotificationCount = (state = intialState, { type, payload }) => {
  switch (type) {
    case Actiontypes.NOTIFICATION:
      return { ...state, notificationCount: payload };
    default:
      return state;
  }
};

export const CommonInfo = (state = intialState, { type, payload }) => {
  switch (type) {
    case Actiontypes.COMMONINFO:
      return { ...state, commonInfo: payload };
    default:
      return state;
  }
};


export const Pathname = (state = intialState, { type, payload }) => {
  switch (type) {
    case Actiontypes.PATHNAME:
      return { ...state, pathname: payload };
    default:
      return state;
  }
};





