import React from 'react'
import {  useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Logout from './Logout';
import { defaultConfig } from '../../config';


export default function AuthLogin({ t, isheader, notificationCount="" }) {

  const token = localStorage.getItem('accessToken')
  const user = JSON.parse(localStorage.getItem('userData'));
  const users = useSelector((state) => state.UserProfile.profile);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkZero = (notificationCount) => {
    if(!notificationCount) return 0;
    else return notificationCount
  }

  return (
    <>
      {token ?
        <>
          <div className="dropdown dropdownbell">
            <Link to="/notification">
              <img src="/image/bell.png" className="img-fluid" alt="" />
              <span className="countno">{checkZero(notificationCount)}</span>
            </Link>
          </div>
          <div className="dropdown">

            <Link
              to=""
              className="dropdown-toggle dropdown-toggle-custom notranslate"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="user_log">
                <img src={users.profilePic ? defaultConfig.imagePath + users?.profilePic : user?.profilePic ? defaultConfig.imagePath + user?.profilePic : '/image/tuser.png'} className="img-fluid" alt="" />
              </span>{" "}
              {users?.firstName ? users?.firstName : user?.firstName}
            </Link>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-custom">
              <li>
                <Link className="dropdown-item" to="/dashboard">
                {t("dashboard")}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="#" onClick={handleClickOpen}>
                {t("logout")}
                </Link>
              </li>
            </ul>
          </div>
        </>
        :

        <>
          <Link to="/get-started" className="job-psot-btn">
            <span className="me-2">
              <img src="/image/user_sm.png" className="img-fluid" alt="" />
            </span>
            {t("login")} / {t("register")}
          </Link>
        </>
      }
      <Logout open={open} handleClose={handleClose} type='logout'/>
    </>
  )
}
