import { useLocation } from 'react-router-dom';
import { Pathname } from '../Redux/Redux/Action/Action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const NormalGuard = ({ children }) => {
  const dispatch=useDispatch();
  const location = useLocation();
  useEffect(() => {
   dispatch(Pathname(location.pathname))
  }, []);
  return <>
  {children}</>;
};
export default NormalGuard;